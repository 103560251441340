import React, { ReactNode } from 'react';
import { FocusTrap } from '@web-apps/focus-trap';
import classnames from 'classnames';

import { useTheme } from '@web-apps/theming';
import classes from './DropOut.module.scss';

interface Props {
	id: string;
	children: ReactNode;
	open?: boolean;
	ariaLabelledBy: string;
}

const DropOut = ({ id, children, ariaLabelledBy, open = false }: Props) => {
	const { theme } = useTheme();
	return (
		<FocusTrap initialFocus={`#${id}`} active={open} allowOutsideClick>
			<div
				role="dialog"
				aria-modal="true"
				className={classnames(theme, classes.dropOut, { [classes.open]: open })}
				id={id}
				data-testid={id}
				aria-labelledby={ariaLabelledBy}
			>
				{children}
			</div>
		</FocusTrap>
	);
};

export default DropOut;

export const DropOutDivider = () => (
	<div className={classes.divider}>
		<hr />
	</div>
);
