import { FocusTrapProvider } from '@web-apps/focus-trap';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { BotifyProvider } from './botify/BotifyContext';
import { AudioPlayerProvider } from './components/AudioPlayerContext/AudioPlayerContext';
import { ErrorHandler } from './helpers/ErrorHandler';
import PandaProvider from './helpers/PandaProvider';
import { startPollingService } from './poll-service';
import { Provider as StoreProvider } from './redux';
import {
	channelHistoryPersist,
	rehydrateChannelHistory,
} from './redux/slices/channelHistory/persitor';
import { createStore } from './redux/store';
import { Root } from './routes/Root';
import { prepareGlobalInstanaFunction } from './third-party/instana';

// Make sure styles are loaded
import './styles/app.scss';
import '@panda/ui';

startPollingService();
prepareGlobalInstanaFunction();

const store = createStore({
	channelHistory: rehydrateChannelHistory(),
});

channelHistoryPersist(store);

createRoot(document.getElementById('root')!).render(
	<ErrorHandler>
		<div style={{ height: '100%' }}>
			<StoreProvider store={store}>
				<BotifyProvider>
					<FocusTrapProvider>
						<PandaProvider>
							<AudioPlayerProvider>
								<BrowserRouter>
									<Root />
								</BrowserRouter>
							</AudioPlayerProvider>
						</PandaProvider>
					</FocusTrapProvider>
				</BotifyProvider>
			</StoreProvider>
		</div>
	</ErrorHandler>
);
