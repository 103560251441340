import React, { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import { useLocation } from 'react-router';
import { useTheme } from '@web-apps/theming';
import UserAvatar from '../components/UserAvatar';
import classes from './UserDropdown.module.scss';
import { accountManagementPath, eventlistPath, isAccountSection } from '../routes/paths';
import auth from '../utils/authenticate/auth';
import { AccountNumber } from '../navigation/logout/AccountNumber';
import DropOut, { DropOutDivider } from './DropOut';
import useKeyboardEvents from './hooks/useKeyboardEvents';
import useOutsideClick from './hooks/useOutsideClick';
import useLinks from '../redux/slices/links/hooks';
import { useTranslate } from '../redux/slices/translations';
import { useCurrentUser } from '../redux/modules/users';
import { useAccount } from '../redux/slices/account';
import { hasRestriction, useRestrictions } from '../redux/modules/restrictions';
import { useUserInfo } from '../redux/slices/userinfo';

const UserDropdown = () => {
	const ref = useRef<HTMLDivElement>(null);
	const currentUser = useCurrentUser();
	const translate = useTranslate();
	const { links } = useLinks();
	const accountData = useAccount();
	const [open, setOpen] = useState(false);
	const user = useUserInfo();
	const restrictions = useRestrictions(['CAN_USE_PBX_FEATURES']);
	const canUsePbxFeatures = hasRestriction(restrictions.data, 'CAN_USE_PBX_FEATURES');
	const location = useLocation();
	const { theme } = useTheme();
	useKeyboardEvents('Escape', () => setOpen(false), open);
	useOutsideClick(ref, () => setOpen(false), open);
	const hasCustomLogo = !!accountData?.logoUrl;

	if (!currentUser) {
		return null;
	}

	function renderAreaSwitchingButton() {
		if (!canUsePbxFeatures || !user.isAdmin) return null;

		if (isAccountSection(location.pathname)) {
			return (
				<li data-testid="user_admin_link">
					<a href={eventlistPath.build()}>{translate('USER_AREA')}</a>
				</li>
			);
		}

		return (
			<li data-testid="admin_area_link">
				<a href={accountManagementPath.build()}>{translate('ADMIN_AREA')}</a>
			</li>
		);
	}

	return (
		<div id="userdropdown" className={classes.userDropDown} ref={ref}>
			<button
				type="button"
				className={classnames(theme, classes.userDropDownButton, {
					[classes.withLogo]: hasCustomLogo,
				})}
				onClick={() => setOpen(!open)}
				aria-controls="user-menu"
				aria-expanded={open}
				aria-haspopup="true"
				id="user-dropdown-button"
			>
				{hasCustomLogo && (
					<img
						src={accountData?.logoUrl}
						alt=""
						data-testid="custom_logo"
						className={classes.logo}
					/>
				)}

				<UserAvatar
					user={currentUser}
					size={hasCustomLogo && window.innerWidth >= 768 ? 'medium' : 'large'}
				/>
			</button>

			<DropOut id="user-menu" open={open} ariaLabelledBy="user-dropdown-button">
				<ul>
					<li>
						<div className={classes.currentUser}>
							<UserAvatar user={currentUser} size="large" editable />
							<div className={classes.currentUserRightContainer}>
								<div className={classes.customerName}>
									{currentUser.firstname} {currentUser.lastname}
								</div>
								<AccountNumber className={classes.accountNumber} />
							</div>
						</div>
					</li>
					<li>
						<DropOutDivider />
					</li>
					{renderAreaSwitchingButton()}
					<li>
						<NavLink to="/personal-settings" onClick={() => setOpen(false)}>
							{translate('PERSONAL_SETTINGS')}
						</NavLink>
					</li>
					<li>
						<DropOutDivider />
					</li>
					<li>
						<div className={classes.logout}>
							<button type="button" onClick={() => auth.logoutAndRedirectToLogoutPage(links)}>
								{translate('LOGOUT')}
							</button>
						</div>
					</li>
				</ul>
			</DropOut>
		</div>
	);
};

export default UserDropdown;
