import { Button, EmptyState, Headline, Tab, Tabs, ViewHeader } from '@panda/ui';
import { debounce, isEqual } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Route, RouteComponentProps, withRouter } from 'react-router';
import { getE164Number, isAnonymous } from '@web-apps/phonenumbers-utils';
import classnames from 'classnames';
import { connect, ReduxProps } from '../../redux';

import api from '../../api';
import {
	CallHistoryEntry,
	FaxHistoryEntry,
	HistoryEntry,
	HistoryEntryType,
	SmsHistoryEntry,
	VoicemailHistoryEntry,
} from '../../api/types/events';
import { Phoneline } from '../../api/types/phonelines';
import EmptyFilterIllustration from '../../components/images/illustrations/sipgate_team_eventlist_Filter.svg';
import EmptyInboxIllustration from '../../components/images/illustrations/sipgate_team_keineEreignisse.svg';
import EmptyArchiveIllustration from '../../components/images/illustrations/sipgate_team_keineEreignisse_Archiv.svg';
import { Pagination } from '../../components/Pagination';
import { Select } from '../../components/Select';
import { LogoSpinner } from '../../components/spinner/LogoSpinner';
import errorIllustration from '../../media/illustrations/error.svg';
import { deregisterPoller, registerPoller } from '../../poll-service';
import { Group } from '../../redux/modules/groups';
import { LinksState } from '../../redux/slices/links';
import { UserInfo } from '../../redux/slices/userinfo';
import { getTypeFilterArgument } from './state/history/fetchHistory/action';
import { OverlayTransition } from '../../slide-in/OverlayTransition';
import { SlideIn } from '../../slide-in/SlideIn';
import { pusher } from '../../third-party/pusher';
import { DisabledEventlistEmptyState } from './emptystates/DisabledEventlistEmptyState';
import classes from './EventList.module.scss';
import { CallEvent } from './events/CallEvent';
import { FaxEvent } from './events/FaxEvent';
import { SmsEvent } from './events/SmsEvent';
import VoicemailEvent from './events/VoicemailEvent';
import { FilterActionBar } from './FilterActionBar';
import { NormalizedEvent } from './normalize/events';
import { activeFilterCount, modifyHash } from './selections';
import { selectionParsers, Selections, TypeType } from './selections/parsers';
import { ChangeUrlParameter } from './selections/types';
import { LabelSlideIn } from './slide-ins/LabelSlideIn';
import { State } from './state/state';
import { HistoryStore } from './state/store';
import { TimeToLiveHint } from './TimeToLiveHint';
import { hasRestriction, RestrictionState } from '../../redux/modules/restrictions';
import { Acd } from '../../redux/slices/acds';
import auth from '../../utils/authenticate/auth';
import { Label } from '../../redux/modules/labels';
import { Translate } from '../../redux/slices/translations';
import { WithDialogProps, withDialogs } from '../../routes/paths/dialogs';
import { Sms } from '../../redux/slices/sms';
import { Faxline } from '../../redux/slices/faxlines';
import { NormalizedEventEntries } from './state/types';
import BorderedContainer from '../../components/device-table/BorderedContainer';

const SIX_HOURS = 21600000;
const EDIT_LABELS = 'labels';
const MAX_EVENTS_EXPORT = 1000;

const selectEntriesByType = (
	entries: NormalizedEventEntries,
	types: HistoryEntryType[]
): NormalizedEventEntries => {
	const filteredResult =
		entries.result?.normalized.filter(event => types.includes(event.originalEvent.type)) || [];
	return {
		error: entries.error,
		tooManyEvents: entries.tooManyEvents,
		abortController: entries.abortController,
		result: {
			normalized: filteredResult,
			totalCount: entries.result?.totalCount || 0,
		},
	};
};

const mapStateToProps = (state: State, { selectedTypes }: ExternalProps) => ({
	pageSize: state.pageSize,
	entries: selectEntriesByType(state.entries, selectedTypes),
	labels: state.labels,
	contacts: state.contacts,
	runningAudioPlayer: state.runningAudioPlayer,
});

interface ExternalProps {
	storeActions: HistoryStore;
	translate: Translate;
	webuserId: string;
	masterSipId: string;
	signalSuccess: (message: string) => void;
	signalFailure: (message: string) => void;
	signalInfo: (message: string) => void;
	selections: Selections;
	enabled: boolean;
	isLimitedTo30Days: boolean;
	groups?: Group[];
	phonelines?: Phoneline[];
	acds?: Acd[];
	userFax?: Faxline;
	sms: Sms[];
	onOpenBlacklist: () => void;
	onBlacklist?: (number: string, blacklisted: boolean) => Promise<void>;
	onNewContact: (number: string) => void;
	onAddNumberToContact: (number: string) => void;
	onClick2Dial: (number: string) => void;
	onAnswerWithSms?: (number: string) => void;
	onClick2Play?: (eventId: string) => void;
	changeUrlParameter: ChangeUrlParameter;
	fetchContactsByPhonenumbers: (e164Numbers: string[]) => void;
	links: LinksState;
	userinfo: UserInfo;
	restrictions: RestrictionState;
	createLabel: (name: string) => Promise<Label>;
	editLabel: (id: number, name: string) => Promise<void>;
	deleteLabel: (id: number) => Promise<void>;
	selectedTypes: HistoryEntryType[];
	eventListHeading: string;
}

type Props = ReduxProps<typeof mapStateToProps> &
	ExternalProps &
	RouteComponentProps &
	WithDialogProps;

const mapHistoryTypesToFilterTypes = (types: HistoryEntryType[]): TypeType[] => {
	return types.map(type => type.toLowerCase()) as TypeType[];
};

class PureEventList extends React.PureComponent<Props> {
	public state = {
		intervalId: '',
	};

	public componentDidMount() {
		this.registerEventPushHandler();
		this.startFallbackPolling();

		this.fetchHistory(true);
	}

	public componentDidUpdate(prevProps: Readonly<Props>): void {
		if (!isEqual(prevProps.selections, this.props.selections)) {
			this.fetchHistory(true);
		}
	}

	public componentWillUnmount() {
		this.unregisterEventPushHandler();
		this.stopFallbackPolling();
	}

	private unbindPusher = () => {};

	private startFallbackPolling = () => {
		if (this.state.intervalId) {
			return;
		}

		const fn = () => {
			this.fetchHistory(false);
		};

		this.setState({
			intervalId: registerPoller(fn.bind(this), SIX_HOURS),
		});
	};

	private stopFallbackPolling = () => {
		deregisterPoller(this.state.intervalId);
		this.setState({
			intervalId: '',
		});
	};

	private registerEventPushHandler = () => {
		const channel = `${this.props.masterSipId}${this.props.webuserId}`;
		const event = 'reloadHistory';
		const debouncedFetch = debounce(() => this.fetchHistory(false), 150);

		pusher.then(client => {
			this.unbindPusher = client.listen(channel, event, () => {
				debouncedFetch();
			});
		});
	};

	private unregisterEventPushHandler = () => {
		this.unbindPusher();
		this.unbindPusher = () => {};
	};

	private exportEventlist = () => {
		this.props.signalInfo(this.props.translate('EXPORT_INFO_SNACKBAR'));

		const filters = {
			connectionIds: this.props.selections.connectionIds,
			types: getTypeFilterArgument(this.props.selections),
			directions: this.props.selections.direction.map(d => d.toUpperCase()) as (
				| 'INCOMING'
				| 'OUTGOING'
			)[],
			status: this.props.selections.status.map(s => s.toUpperCase()) as ('SUCCESS' | 'FAILURE')[],
			directory: [this.props.selections.directory.toUpperCase()],
			starred: this.props.selections.starred.map(s => s.toUpperCase()) as (
				| 'STARRED'
				| 'UNSTARRED'
			)[],
			from: this.props.selections.from,
			to: this.props.selections.to,
			labelIds: this.props.selections.labelIds,
			read: this.props.selections.read.map(s => s.toUpperCase()) as ('READ' | 'UNREAD')[],
			phonenumber: this.props.selections.phonenumber
				? this.props.selections.phonenumber
				: undefined,
		};

		api
			.exportFilteredHistory(filters)
			.then(blob => {
				this.deliverCsvDownloadUrl(blob);
			})
			.catch((e: Response) => {
				if (e.status === 503) {
					api
						.exportFilteredHistory({
							...filters,
							from: moment().subtract(3, 'month'),
							to: moment(),
						})
						.then(blob => {
							this.deliverCsvDownloadUrl(blob);
						})
						.catch(() => {
							this.props.signalFailure(this.props.translate('EXPORT_ERROR_SNACKBAR'));
						});
				} else {
					this.props.signalFailure(this.props.translate('EXPORT_ERROR_SNACKBAR'));
				}
			});
	};

	private deliverCsvDownloadUrl(blob: Blob) {
		const windowUrl = window.URL;
		const url = windowUrl.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.setAttribute('href', url);
		anchor.setAttribute('download', 'export.csv');
		anchor.dispatchEvent(new MouseEvent('click'));
		windowUrl.revokeObjectURL(url);
	}

	private onSaveNote = (eventId: string, note: string) => {
		this.props.storeActions
			.saveNote(eventId, note, this.props.selections)
			.then(() => {
				this.props.signalSuccess(this.props.translate('SAVE_NOTE_SUCCESS_SNACKBAR'));
			})
			.catch(() => {
				this.props.signalFailure(this.props.translate('SAVE_NOTE_FAILURE_SNACKBAR'));
			});
	};

	private onResendFax = (eventId: string) => {
		this.props.storeActions
			.resendFax(eventId)
			.then(() => {
				this.props.signalSuccess(this.props.translate('RESEND_FAX_SUCCESS'));
			})
			.catch(() => {
				this.props.signalFailure(this.props.translate('RESEND_FAX_FAILURE'));
			});
	};

	private onDelete = () => {
		if (!this.props.entries.result) {
			return;
		}

		const selectedIds = this.props.entries.result.normalized
			.filter(entry => entry.selected)
			.map(entry => entry.originalEvent.id);

		this.props.storeActions
			.deleteEntries(selectedIds, this.props.selections)
			.then(() => {
				this.props.signalSuccess(
					this.props.translate('DELETE_SUCCESS_SNACKBAR', selectedIds.length)
				);
			})
			.catch(() => {
				this.props.signalFailure(this.props.translate('DELETE_FAILURE_SNACKBAR'));
			});
	};

	private onArchive = () => {
		if (!this.props.entries.result) {
			return;
		}

		const selectedIds = this.getSelectedIds();

		this.props.storeActions
			.archiveEntries(selectedIds, this.props.selections)
			.then(() => {
				this.props.signalSuccess(
					this.props.translate('ARCHIVE_SUCCESS_SNACKBAR', selectedIds.length)
				);
			})
			.catch(() => {
				this.props.signalFailure(this.props.translate('ARCHIVE_FAILURE_SNACKBAR'));
			});
	};

	private onMarkRead = () => {
		if (!this.props.entries.result) {
			return;
		}

		const selectedIds = this.getSelectedIds();

		this.props.storeActions.markRead(selectedIds).catch(() => {
			this.props.signalFailure(this.props.translate('MARK_READ_FAILURE_SNACKBAR'));
			this.fetchHistory(false);
		});
	};

	private onMarkUnread = () => {
		if (!this.props.entries.result) {
			return;
		}

		const selectedIds = this.getSelectedIds();

		this.props.storeActions.markUnread(selectedIds).catch(() => {
			this.props.signalFailure(this.props.translate('MARK_UNREAD_FAILURE_SNACKBAR'));
			this.fetchHistory(false);
		});
	};

	private getSelectedIds = () => {
		if (this.props.entries.result != null) {
			return this.props.entries.result.normalized
				.filter(entry => entry.selected)
				.map(entry => entry.originalEvent.id);
		}
		return [];
	};

	private onDearchive = () => {
		if (!this.props.entries.result) {
			return;
		}

		const selectedIds = this.props.entries.result.normalized
			.filter(entry => entry.selected)
			.map(entry => entry.originalEvent.id);

		this.props.storeActions
			.dearchiveEntries(selectedIds, this.props.selections)
			.then(() => {
				this.props.signalSuccess(
					this.props.translate('DEARCHIVE_SUCCESS_SNACKBAR', selectedIds.length)
				);
			})
			.catch(() => {
				this.props.signalFailure(this.props.translate('DEARCHIVE_FAILURE_SNACKBAR'));
			});
	};

	private onEditLabels = () => {
		this.props.history.push({
			pathname: `${this.props.location.pathname}/${EDIT_LABELS}`,
			hash: this.props.location.hash,
		});
	};

	private onCloseLabelsSlideIn = () => {
		this.props.history.push({
			pathname: this.props.match.url,
			hash: this.props.location.hash,
		});
	};

	private resetLocationHash = (newValues: Partial<Selections>) => {
		return modifyHash(selectionParsers, newValues, this.props.location.hash);
	};

	private onBlacklist = (number: string, blacklisted: boolean) => {
		if (!this.props.onBlacklist) {
			return;
		}

		this.props
			.onBlacklist(number, blacklisted)
			.then(() => {
				this.props.signalSuccess(
					this.props.translate(
						blacklisted ? 'BLACKLIST_SUCCESS_SNACKBAR' : 'BLACKLIST_DELETE_SUCCESS_SNACKBAR',
						number
					)
				);
			})
			.catch(() => {
				this.props.signalFailure(
					this.props.translate(
						blacklisted ? 'BLACKLIST_FAILURE_SNACKBAR' : 'BLACKLIST_DELETE_FAILURE_SNACKBAR',
						number
					)
				);
			});
	};

	private onShowArchived = () => {
		this.props.changeUrlParameter({ directory: 'archive', offset: 0 });
	};

	private onShowInbox = () => {
		this.props.changeUrlParameter({ directory: 'inbox', offset: 0 });
	};

	private onStar = (eventId: string, starred: boolean) => {
		this.props.storeActions.toggleStar(eventId, starred ? 'STARRED' : 'UNSTARRED').catch(() => {
			this.props.signalFailure(this.props.translate('STAR_FAILURE_SNACKBAR'));
		});
	};

	private onPageChange = (pageOffset: number) => {
		this.props.changeUrlParameter({ offset: pageOffset });
	};

	private onPagesizeChange = (value: string) => {
		this.props.storeActions.changePageSize(parseInt(value, 10), this.props.selections);
	};

	private onAttachLabel = (labelId: number) => {
		if (!this.props.labels) {
			return;
		}

		const label = this.props.labels.find(l => l.id === labelId);
		if (!label) {
			return;
		}
		this.props.storeActions
			.attachLabel(labelId, this.getSelectedIds(), this.props.selections)
			.catch(() => {
				this.props.signalFailure(this.props.translate('ATTACH_LABEL_FAILURE_SNACKBAR', label.name));
			});
	};

	private onDetachLabel = (labelId: number) => {
		if (!this.props.labels) {
			return;
		}

		const label = this.props.labels.find(l => l.id === labelId);
		if (!label) {
			return;
		}

		this.props.storeActions
			.detachLabel(labelId, this.getSelectedIds(), this.props.selections)
			.catch(() => {
				this.props.signalFailure(this.props.translate('DETACH_LABEL_FAILURE_SNACKBAR', label.name));
			});
	};

	private fetchHistory(isUserChange: boolean) {
		const selectedTypes =
			this.props.selections.type.length === 0
				? this.props.selectedTypes.map(type => type.toLowerCase())
				: this.props.selections.type;
		this.props.storeActions
			.fetchHistory({ ...this.props.selections, type: selectedTypes }, isUserChange)
			.then(apiResult => {
				if (!apiResult) {
					return;
				}

				const phonenumbers = new Set(
					(apiResult.items as HistoryEntry[])
						.flatMap(entry => [entry.source, entry.target])
						.filter(number => !isAnonymous(number))
						.map(number => getE164Number(number, this.props.userinfo.domain))
				);

				this.props.fetchContactsByPhonenumbers(Array.from(phonenumbers));
			});
	}

	private isAnyEntrySelected() {
		return !!(
			this.props.entries.result &&
			this.props.entries.result.normalized.find(entry => entry.selected)
		);
	}

	private isFiltered() {
		return activeFilterCount(selectionParsers, this.props.selections) > 0;
	}

	private isLastPage() {
		return (
			this.props.entries.result === null ||
			this.props.selections.offset + this.props.entries.result.normalized.length ===
				this.props.entries.result.totalCount
		);
	}

	private renderError() {
		return (
			<BorderedContainer>
				<EmptyState
					context="section"
					shape={2}
					image={<img alt="" src={errorIllustration} height="100%" />}
					heading={this.props.translate('EVENTLIST_LOADING_ERROR_TITLE')}
					text={<p>{this.props.translate.markdown.block('EVENTLIST_LOADING_ERROR_TEXT')}</p>}
					actionElements={[]}
				/>
			</BorderedContainer>
		);
	}

	private renderEmptyInbox() {
		return (
			<div className={classes.pandaEmptyState}>
				<EmptyState
					context="view"
					heading={this.props.translate('EVENTLIST_EMPTY_INBOX_TITLE')}
					text={this.props.translate(
						auth.isClassicPBXCustomer()
							? 'EVENTLIST_EMPTY_INBOX_TEXT'
							: 'NEO_EVENTLIST_EMPTY_INBOX_TEXT'
					)}
					actionElements={[]}
					image={
						<img
							alt=""
							src={EmptyInboxIllustration}
							className={classes.illustration}
							height="100%"
						/>
					}
				/>
			</div>
		);
	}

	private renderEmptyInvalidOffset() {
		return (
			<BorderedContainer>
				<EmptyState
					context="section"
					shape={3}
					heading={this.props.translate('EVENTLIST_EMPTY_OFFSET_TITLE')}
					text={this.props.translate('EVENTLIST_EMPTY_OFFSET_TEXT')}
					actionElements={[
						{
							type: 'link',
							label: this.props.translate('EVENTLIST_EMPTY_OFFSET_BUTTON'),
							to: this.resetLocationHash({ offset: 0 }),
							direction: 'internal',
						},
					]}
					image={<img alt="" src={errorIllustration} height="100%" />}
				/>
			</BorderedContainer>
		);
	}

	private renderEmptyAndFiltered() {
		return (
			<BorderedContainer>
				<EmptyState
					context="section"
					shape={4}
					heading={this.props.translate('EVENTLIST_EMPTY_TITLE')}
					image={<img alt="" src={EmptyFilterIllustration} className={classes.illustration} />}
					text={this.props.translate('EVENTLIST_EMPTY_TEXT')}
					actionElements={[]}
				/>
			</BorderedContainer>
		);
	}

	private renderEmptyArchive() {
		return (
			<div className={classes.pandaEmptyState}>
				<EmptyState
					context="view"
					heading={this.props.translate('EVENTLIST_EMPTY_ARCHIVE_TITLE')}
					text={this.props.translate('EVENTLIST_EMPTY_ARCHIVE_TEXT')}
					image={
						<img
							alt=""
							src={EmptyArchiveIllustration}
							className={classes.illustration}
							height="100%"
						/>
					}
					actionElements={[]}
				/>
			</div>
		);
	}

	private renderDisabledEventlist() {
		return (
			<DisabledEventlistEmptyState
				translate={this.props.translate}
				links={this.props.links}
				userInfo={this.props.userinfo}
			/>
		);
	}

	private renderPending() {
		return <LogoSpinner />;
	}

	private renderPageControl() {
		if (!this.props.entries.result) {
			return null;
		}

		return (
			<div className={classes.pageControl}>
				<div className={classes.export}>
					<div>
						<div className={classes.exportTextButton}>
							<Button onClick={this.exportEventlist} variant="quiet" icon="export">
								{`${this.props.translate('EVENTLIST_EXPORT')} [${
									this.props.entries.result.totalCount > MAX_EVENTS_EXPORT
										? MAX_EVENTS_EXPORT
										: this.props.entries.result.totalCount
								}]`}
							</Button>
						</div>
						<div className={classes.exportIconButton}>
							<Button onClick={this.exportEventlist} variant="quiet" iconOnly icon="export">
								{`${this.props.translate('EVENTLIST_EXPORT')} [${
									this.props.entries.result.totalCount > MAX_EVENTS_EXPORT
										? MAX_EVENTS_EXPORT
										: this.props.entries.result.totalCount
								}]`}
							</Button>
						</div>
					</div>
				</div>
				<div className={classes.center}>
					<Pagination
						translate={this.props.translate}
						firstShownEntry={
							this.props.entries.result.normalized.length === 0
								? 0
								: this.props.selections.offset + 1
						}
						lastShownEntry={
							this.props.selections.offset + this.props.entries.result.normalized.length
						}
						onPageChange={this.onPageChange}
						pageSize={this.props.pageSize}
						totalEntries={this.props.entries.result.totalCount}
						hideTotal={this.props.entries.tooManyEvents && !this.props.selections.from}
					/>

					<Select
						label={`${this.props.translate('EVENTLIST_PAGESIZE')} ${this.props.pageSize}`}
						value={this.props.pageSize}
						onChange={this.onPagesizeChange}
						className={classes.pageSize}
					>
						<option value={25}>25</option>
						<option value={50}>50</option>
						<option value={100}>100</option>
					</Select>
				</div>
				{/* div required for flex with left + center hack */}
				<div className={classes.filler} />
			</div>
		);
	}

	private renderContent() {
		if (!this.props.enabled) {
			return this.renderDisabledEventlist();
		}

		if (this.props.entries.error) {
			return this.renderError();
		}

		if (!this.props.entries.result) {
			return this.renderPending();
		}

		if (this.props.entries.result.normalized.length === 0) {
			if (this.props.selections.offset) {
				return this.renderEmptyInvalidOffset();
			}
			if (this.isFiltered()) {
				return this.renderEmptyAndFiltered();
			}

			if (this.props.selections.directory === 'archive') {
				return this.renderEmptyArchive();
			}

			return this.renderEmptyInbox();
		}

		const hasNonInternalContacts =
			this.props.contacts.filter(contact => contact.scope !== 'INTERNAL').length > 0;

		return (
			<div className={classes.list} data-test-selector="eventlist-entries">
				<ol>
					{this.props.entries.result.normalized.map(event => {
						const runningAudioPlayerId =
							this.props.runningAudioPlayer &&
							this.props.runningAudioPlayer.eventId === event.originalEvent.id
								? this.props.runningAudioPlayer.playerId
								: null;

						switch (event.originalEvent.type) {
							case 'CALL':
								return (
									<CallEvent
										key={event.originalEvent.id}
										domain={this.props.userinfo.domain}
										event={event as NormalizedEvent<CallHistoryEntry>}
										translate={this.props.translate}
										onClick2Dial={this.props.onClick2Dial}
										onAnswerWithSms={this.props.onAnswerWithSms}
										onNewContact={this.props.onNewContact}
										onAddNumberToContact={
											hasNonInternalContacts ? this.props.onAddNumberToContact : undefined
										}
										onNoteChange={this.onSaveNote}
										onRead={this.props.storeActions.markRead}
										onPlayPause={this.props.storeActions.changeActiveAudioPlayer}
										playingRecordingId={runningAudioPlayerId}
										onSelection={this.props.storeActions.toggleEntrySelection}
										onBlacklist={this.props.onBlacklist ? this.onBlacklist : undefined}
										onStar={this.onStar}
										onOpenBlacklist={this.props.onOpenBlacklist}
									/>
								);
							case 'FAX':
								return (
									<FaxEvent
										domain={this.props.userinfo.domain}
										onResend={this.onResendFax}
										key={event.originalEvent.id}
										event={event as NormalizedEvent<FaxHistoryEntry>}
										translate={this.props.translate}
										onAnswerWithSms={this.props.onAnswerWithSms}
										onNewContact={this.props.onNewContact}
										onAddNumberToContact={
											hasNonInternalContacts ? this.props.onAddNumberToContact : undefined
										}
										onNoteChange={this.onSaveNote}
										onRead={this.props.storeActions.markRead}
										onSelection={this.props.storeActions.toggleEntrySelection}
										onStar={this.onStar}
									/>
								);
							case 'VOICEMAIL':
								return (
									<VoicemailEvent
										domain={this.props.userinfo.domain}
										key={event.originalEvent.id}
										event={event as NormalizedEvent<VoicemailHistoryEntry>}
										onRead={this.props.storeActions.markRead}
										onNoteChange={this.onSaveNote}
										onNewContact={this.props.onNewContact}
										onAddNumberToContact={
											hasNonInternalContacts ? this.props.onAddNumberToContact : undefined
										}
										onClick2Dial={this.props.onClick2Dial}
										onAnswerWithSms={this.props.onAnswerWithSms}
										onClick2Play={this.props.onClick2Play}
										translate={this.props.translate}
										onPlayPause={this.props.storeActions.changeActiveAudioPlayer}
										paused={!runningAudioPlayerId}
										onSelection={this.props.storeActions.toggleEntrySelection}
										onStar={this.onStar}
										restrictions={this.props.restrictions}
										impersonated={auth.getImpersonated()}
									/>
								);
							case 'SMS':
								return (
									<SmsEvent
										domain={this.props.userinfo.domain}
										key={event.originalEvent.id}
										event={event as NormalizedEvent<SmsHistoryEntry>}
										onRead={this.props.storeActions.markRead}
										onNoteChange={this.onSaveNote}
										onNewContact={this.props.onNewContact}
										onAddNumberToContact={
											hasNonInternalContacts ? this.props.onAddNumberToContact : undefined
										}
										onClick2Dial={this.props.onClick2Dial}
										onAnswerWithSms={this.props.onAnswerWithSms}
										translate={this.props.translate}
										onSelection={this.props.storeActions.toggleEntrySelection}
										onStar={this.onStar}
										impersonated={auth.getImpersonated()}
									/>
								);
							default:
								return null;
						}
					})}
				</ol>

				{auth.isClassicPBXCustomer() && this.props.isLimitedTo30Days && this.isLastPage() ? (
					<TimeToLiveHint
						translate={this.props.translate}
						links={this.props.links}
						userInfo={this.props.userinfo}
					/>
				) : null}

				{this.renderPageControl()}
			</div>
		);
	}

	public render() {
		const isInInbox = this.props.selections.directory === 'inbox';
		const isInArchive = this.props.selections.directory === 'archive';
		const onTabChange = (activeTabIndex: number) =>
			(activeTabIndex === 0 && this.onShowInbox()) ||
			(activeTabIndex === 1 && this.onShowArchived());

		const actionElements: React.ReactNode[] = [];

		const smsTypeSelected = this.props.selectedTypes.includes('SMS');

		if (auth.isNeoPBXCustomer() && smsTypeSelected && this.props.sms.length > 0) {
			actionElements.push(
				<Button
					onClick={() => {
						this.props.dialogs.smsSend.open();
					}}
				>
					{this.props.translate('SMS_AND_FAX_VIEW_SEND_SMS')}
				</Button>
			);
		}

		const faxTypeSelected = this.props.selectedTypes.includes('FAX');
		if (
			auth.isNeoPBXCustomer() &&
			faxTypeSelected &&
			hasRestriction(this.props.restrictions.items, 'CAN_SEND_FAX', this.props.userinfo.sub) &&
			this.props.userFax
		) {
			actionElements.push(
				<Button
					onClick={() => {
						this.props.dialogs.sendFax.open({ webuserId: this.props.userinfo.sub });
					}}
				>
					{this.props.translate('SMS_AND_FAX_VIEW_SEND_FAX')}
				</Button>
			);
		}

		return (
			<>
				{auth.isNeoPBXCustomer() ? (
					<ViewHeader heading={this.props.eventListHeading} actionElements={actionElements} />
				) : (
					<Headline screenreaderOnly>{this.props.eventListHeading}</Headline>
				)}
				<div className={classnames(classes.controlBar)}>
					<Tabs
						onChange={onTabChange}
						landmarkLabel={this.props.translate('EVENTLIST_TABBAR_LANDMARK_LABEL')}
					>
						<Tab label={this.props.translate('EVENTLIST_ALL')} isActive={isInInbox} />
						<Tab label={this.props.translate('EVENTLIST_ARCHIVE')} isActive={isInArchive} />
					</Tabs>
					<FilterActionBar
						translate={this.props.translate}
						selections={this.props.selections}
						labels={this.props.labels}
						contacts={this.props.contacts}
						onFilterChange={this.props.changeUrlParameter}
						showArchiveButton={isInInbox}
						showDearchiveButton={isInArchive}
						onMarkRead={this.onMarkRead}
						onMarkUnread={this.onMarkUnread}
						hasSelectedEvents={this.isAnyEntrySelected()}
						onDelete={this.onDelete}
						onArchive={this.onArchive}
						onDearchive={this.onDearchive}
						onEditLabels={this.onEditLabels}
						matchedEventCount={
							this.props.entries.result ? this.props.entries.result.normalized.length : null
						}
						selectMultiple={this.props.storeActions.selectMultiple}
						events={this.props.entries.result ? this.props.entries.result.normalized : []}
						groups={this.props.groups}
						acds={this.props.acds}
						phonelines={this.props.phonelines}
						domain={this.props.userinfo.domain}
						selectableTypes={mapHistoryTypesToFilterTypes(this.props.selectedTypes)}
					/>
				</div>
				<div className={classes.eventList}>{this.renderContent()}</div>

				<OverlayTransition
					transitionClasses={SlideIn.transitionClasses}
					timeout={SlideIn.transitionDuration}
					location={this.props.location}
					basepath={this.props.match.url}
				>
					<Route
						path={`${this.props.match.url}/labels`}
						render={({ match, location, history }) => (
							<LabelSlideIn
								translate={this.props.translate}
								onClose={this.onCloseLabelsSlideIn}
								events={this.props.entries}
								labels={this.props.labels}
								onAttachLabel={this.onAttachLabel}
								onDetachLabel={this.onDetachLabel}
								createLabel={this.props.createLabel}
								editLabel={this.props.editLabel}
								deleteLabel={this.props.deleteLabel}
								basePath={match.url}
								location={location}
								history={history}
								signalFailure={this.props.signalFailure}
							/>
						)}
					/>
				</OverlayTransition>
			</>
		);
	}
}

export const EventList = connect(mapStateToProps)(withDialogs(withRouter(PureEventList)));
