import React from 'react';
import { EmptyState } from '@panda/ui';
import EventListIllustration from '../../../components/images/illustrations/sipgate_team_eventlist.svg';
import { LinksState } from '../../../redux/slices/links';
import { Translate } from '../../../redux/slices/translations';
import { UserInfo } from '../../../redux/slices/userinfo';
import BorderedContainer from '../../../components/device-table/BorderedContainer';

interface Props {
	translate: Translate;
	links: LinksState;
	userInfo: UserInfo;
}

export class DisabledEventlistEmptyState extends React.Component<Props> {
	private getText() {
		if (this.props.userInfo.isAdmin) {
			return <p>{this.props.translate('EVENTLIST_DISABLED_TEXT')}</p>;
		}
		return (
			<>
				<p>{this.props.translate('EVENTLIST_DISABLED_TEXT')}</p>
				<p>{this.props.translate('EVENTLIST_DISABLED_NON_ADMIN_HINT')}</p>
			</>
		);
	}

	private getActionElement() {
		if (this.props.userInfo.isAdmin) {
			return [
				{
					type: 'link' as const,
					to: this.props.links.items.accountSettingsUrl,
					label: this.props.translate('PANDA_ICON_ADD'),
					direction: 'internal' as const,
				},
			];
		}

		return [];
	}

	public render() {
		if (!this.props.links.fetched) {
			return null;
		}

		return (
			<BorderedContainer>
				<EmptyState
					context="section"
					shape={1}
					image={<img alt="" width="100%" src={EventListIllustration} />}
					heading={this.props.translate('EVENTLIST_DISABLED_TITLE')}
					text={this.getText()}
					actionElements={this.getActionElement()}
				/>
			</BorderedContainer>
		);
	}
}
