import React from 'react';
import { Tooltip } from '../../components/tooltips/Tooltip';
import smallInfoIcon from '../../media/icons/icon-info-dark-line-default-16.svg';
import { LinksState } from '../../redux/slices/links';
import { TimeToLiveSettingsLink } from './TimeToLiveSettingsLink';
import classes from './TimeToLiveHint.module.scss';
import { UserInfo } from '../../redux/slices/userinfo';
import { Translate } from '../../redux/slices/translations';

interface Props {
	translate: Translate;
	links: LinksState;
	userInfo: UserInfo;
}

export class TimeToLiveHint extends React.Component<Props> {
	public render() {
		if (!this.props.links.fetched) {
			return null;
		}

		return (
			<div className={classes.timeToLive}>
				{this.props.translate('EVENT_LIST_TIME_TO_LIVE_30_DAYS')}{' '}
				{this.props.userInfo.isAdmin ? (
					<TimeToLiveSettingsLink
						renderButton={false}
						accountSettingsUrl={this.props.links.items.accountSettingsUrl}
						translate={this.props.translate}
					/>
				) : (
					<>
						<Tooltip overlay={this.props.translate('EVENT_LIST_TIME_TO_LIVE_NO_ADMIN_HINT')}>
							<span>
								<img alt="" className={classes.icon} src={smallInfoIcon} />
							</span>
						</Tooltip>

						<span className={classes.tooltipMobile}>
							{this.props.translate('EVENT_LIST_TIME_TO_LIVE_NO_ADMIN_HINT_MOBILE')}
						</span>
					</>
				)}
			</div>
		);
	}
}
