import {
	SideNavigationItem,
	SideNavigationMenu,
	SideNavigationMenuGroup,
	SideNavigationMenuLink,
} from '../../navigation/SideNavigation/SideNavigation.types';
import * as paths from '../../routes/paths';
import eventListIcon from './userMenuIcons/eventList.svg';
import faxAndSmsIcon from './userMenuIcons/fax-and-sms.svg';
import voicemailIcon from './userMenuIcons/voicemail.svg';
import presenceIcon from './userMenuIcons/presence.svg';
import acdIcon from './userMenuIcons/acd.svg';
import contactsIcon from './userMenuIcons/contacts.svg';
import devicesIcon from './userMenuIcons/devices.svg';
import routingIcon from './userMenuIcons/routing.svg';
import settingsIcon from './userMenuIcons/settings.svg';
import appsAndDevicesIcon from './userMenuIcons/apps-and-devices.svg';
import { ApiLinks } from '../../api/types/links';
import { hasRestriction, RestrictionState } from '../../redux/modules/restrictions';
import { AppProperties } from '../../redux/slices/appProperties';

const getSettingsGroup = (links: ApiLinks): SideNavigationMenuGroup => {
	const groupLinks: SideNavigationMenuLink[] = [];

	if (links.notificationsUrl) {
		groupLinks.push({
			kind: 'LINK',
			path: paths.userNotificationsPath.build(),
			title: 'NOTIFICATIONS',
		});
	}

	groupLinks.push({
		kind: 'LINK',
		path: paths.blocklistPath.build(),
		title: 'BLACKLIST',
	});

	if (links.personalSettingsUrl) {
		groupLinks.push({
			kind: 'LINK',
			path: paths.personalSettingsPath.build(),
			title: 'PERSONAL_SETTINGS',
		});
	}

	return {
		kind: 'GROUP',
		icon: settingsIcon,
		title: 'SETTINGS',
		links: groupLinks,
	};
};

export const getUserMenuLinks = (
	links: ApiLinks,
	restrictions: RestrictionState,
	appProperties: AppProperties,
	isNeoPBXCustomer: boolean
): SideNavigationMenu => {
	const items: SideNavigationItem[] = [];

	if (!isNeoPBXCustomer && hasRestriction(restrictions.items, 'CAN_SEE_CHANNEL_DASHBOARD')) {
		items.push({
			kind: 'LINK',
			path: paths.acdTeamLeadOverviewPath.build(),
			title: 'CHANNEL_TEAM_LEAD_OVERVIEW',
			icon: acdIcon,
		});
	}

	if (isNeoPBXCustomer) {
		items.push({
			kind: 'LINK',
			path: paths.myChannelsPath.build(),
			title: 'CHANNEL',
			icon: acdIcon,
		});
		items.push({
			kind: 'LINK',
			path: paths.userAreaDevicesPath.build(),
			title: 'ADMINS_APPS_AND_DEVICES_VIEW_HEADING',
			icon: appsAndDevicesIcon,
		});
	} else {
		items.push({
			kind: 'LINK',
			path: paths.eventlistPath.build(),
			title: 'EVENTLISTVIEW_TITLE',
			icon: eventListIcon,
		});
	}

	if (
		!isNeoPBXCustomer &&
		links.presenceContainerUrl &&
		hasRestriction(restrictions.items, 'CAN_USE_WEB_BLF')
	) {
		items.push({
			kind: 'LINK',
			path: links.presenceContainerUrl,
			title: 'PRESENCE',
			icon: presenceIcon,
		});
	}

	items.push({
		kind: 'LINK',
		path: paths.contactsPath.build(),
		title: 'CONTACTS',
		icon: contactsIcon,
	});

	if (!isNeoPBXCustomer) {
		items.push({
			kind: 'LINK',
			path: paths.routingPath.build(),
			title: 'APP_NAVIGATION_ROUTING',
			icon: routingIcon,
		});
	}

	if (appProperties.showSimRelevantOptions) {
		items.push({
			kind: 'LINK',
			path: paths.devicePoolPath.build(),
			title: 'DEVICE_POOL',
			icon: devicesIcon,
		});
	}

	if (isNeoPBXCustomer) {
		items.push({
			kind: 'LINK',
			path: paths.faxAndSmsHistoryPath.build(),
			title: 'SMS_AND_FAX_VIEW_HEADING',
			icon: faxAndSmsIcon,
		});

		items.push({
			kind: 'LINK',
			path: paths.voicemailHistoryPath.build(),
			title: 'VOICEMAIL',
			icon: voicemailIcon,
		});
	}

	items.push(getSettingsGroup(links));

	return { items };
};
