import { createSlice, isAnyOf, UnknownAction } from '@reduxjs/toolkit';
import { Faxline, FaxLineOperationState, FaxlineState, WithId } from './types';
import {
	addFaxLineOwners,
	createFaxLine,
	deleteFaxLine,
	fetchFaxLines,
	removeFaxLineOwners,
	setFaxLineAlias,
	setFaxLineCallerId,
	setFaxLineTagLine,
} from './actions';
import { handleActions } from '../../utils/actions';
import { setGroupAlias } from '../../modules/groups';
import { isApiFaxLineNeo } from './api/types';

const itemComparator = <T extends WithId>(a: T, b: T) => a.id.localeCompare(b.id);

const updateItems = <T extends WithId>(items: T[], item: T) => {
	if (items.length === 0) {
		return [item];
	}

	const index = items.findIndex(f => f.id === item.id);
	if (index === -1) {
		return [...items, item].sort(itemComparator);
	}
	return items.with(index, item as T).sort(itemComparator);
};

export const initialState: FaxlineState = {
	items: [],
	fetching: [],
	updating: [],
};

const slice = createSlice({
	name: 'faxlines',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchFaxLines.pending, state => ({
			...state,
			fetching: updateItems(state.fetching, {
				id: '*',
				state: 'pending',
			}),
		}));

		builder.addCase(fetchFaxLines.rejected, (state, { payload: error }) => ({
			...state,
			fetching: updateItems(state.fetching, {
				id: '*',
				state: 'failed',
				error,
			} as FaxLineOperationState),
		}));

		builder.addCase(fetchFaxLines.fulfilled, (state, { payload: faxLines }) => {
			const items = faxLines.reduce((faxlines, faxLine) => {
				const faxlineIndex = faxlines.findIndex(f => f.id === faxLine.id);
				if (faxlineIndex > -1) {
					const faxLineItem = faxlines[faxlineIndex];

					return [
						...faxlines.slice(0, faxlineIndex),
						{
							...faxLineItem,
							ownerIds: [...faxLineItem.ownerIds, faxLine.ownerId],
						} as Faxline,
					];
				}

				const { ownerId, ...faxLineWithoutOwner } = faxLine;
				// don't add undefined or null to ownerIds
				if (ownerId !== undefined && ownerId !== null) {
					return [...faxlines, { ...faxLineWithoutOwner, ownerIds: [ownerId] } as Faxline];
				}

				return [...faxlines, { ...faxLineWithoutOwner, ownerIds: [] } as Faxline];
			}, [] as Faxline[]);

			const fetching = (
				[
					{ id: '*', state: 'succeeded' },
					...items.map(i => ({
						id: i.id,
						state: 'succeeded',
					})),
				] as FaxLineOperationState[]
			).sort(itemComparator);

			return {
				...state,
				items,
				fetching,
			};
		});

		builder.addCase(setFaxLineAlias.pending, (state, { meta }) => {
			const oldFaxLine = state.items.find(f => f.id === meta.arg.faxLineId);

			if (oldFaxLine) {
				return {
					...state,
					items: updateItems(state.items, { ...oldFaxLine, alias: meta.arg.alias }),
					updating: updateItems(state.updating, { id: meta.arg.faxLineId, state: 'pending' }),
				};
			}

			return {
				...state,
			};
		});

		builder.addCase(setFaxLineTagLine.pending, (state, { meta }) => {
			const oldFaxLine = state.items.find(f => f.id === meta.arg.faxLineId);

			if (oldFaxLine) {
				return {
					...state,
					items: updateItems(state.items, { ...oldFaxLine, tagline: meta.arg.tagline }),
					updating: updateItems(state.updating, { id: meta.arg.faxLineId, state: 'pending' }),
				};
			}

			return { ...state };
		});

		builder.addCase(createFaxLine.fulfilled, (state, { payload: faxLine }) => {
			if (isApiFaxLineNeo(faxLine)) {
				return {
					...state,
					items: updateItems(state.items, {
						...faxLine,
						tagline: '',
						callerId: 'anonymous',
						canReceive: true,
					}),
					updating: updateItems(state.updating, { id: faxLine.id, state: 'succeeded' }),
				};
			}

			const { ownerId, ...faxLineWithoutOwnerId } = faxLine;
			return {
				...state,
				items: [...state.items, { ...faxLineWithoutOwnerId, ownerIds: [ownerId] }].sort(
					itemComparator
				),
				updating: [...state.fetching, { id: faxLine.id, state: 'succeeded' }].sort(itemComparator),
			};
		});

		builder.addCase(deleteFaxLine.pending, (state, { meta }) => ({
			...state,
			items: state.items.filter(item => item.id !== meta.arg.faxLineId),
		}));

		builder.addCase(setFaxLineCallerId.pending, (state, { meta }) => {
			const oldFaxLine = state.items.find(f => f.id === meta.arg.faxLineId);

			if (oldFaxLine) {
				return {
					...state,
					items: updateItems(state.items, {
						...oldFaxLine,
						callerId: meta.arg.anonymous ? 'anonymous' : meta.arg.callerId,
					}),
					updating: updateItems(state.updating, {
						id: meta.arg.faxLineId,
						state: 'pending',
					} as FaxLineOperationState),
				};
			}

			return {
				...state,
			};
		});

		builder.addCase(addFaxLineOwners.pending, (state, { meta }) => {
			const oldFaxLine = state.items.find(f => f.id === meta.arg.faxLineId);

			if (oldFaxLine) {
				return {
					...state,
					items: updateItems(state.items, {
						...oldFaxLine,
						ownerIds: Array.from(new Set([...oldFaxLine.ownerIds, ...meta.arg.ownerIds])),
					}),
					updating: updateItems(state.updating, {
						id: meta.arg.faxLineId,
						state: 'pending',
					}),
				};
			}

			return {
				...state,
			};
		});

		builder.addCase(removeFaxLineOwners.pending, (state, { meta }) => {
			const oldFaxLine = state.items.find(f => f.id === meta.arg.faxLineId);

			if (oldFaxLine) {
				return {
					...state,
					items: updateItems(state.items, {
						...oldFaxLine,
						ownerIds: Array.from(
							new Set(oldFaxLine.ownerIds.filter(ownerId => !meta.arg.ownerIds.includes(ownerId)))
						),
					}),
					updating: updateItems(state.updating, {
						id: meta.arg.faxLineId,
						state: 'pending',
					}),
				};
			}

			return {
				...state,
			}!;
		});

		builder.addMatcher(
			isAnyOf(
				addFaxLineOwners.fulfilled,
				removeFaxLineOwners.fulfilled,
				setFaxLineAlias.fulfilled,
				setFaxLineCallerId.fulfilled
			),
			(state, { meta }) => ({
				...state,
				updating: updateItems(state.updating, {
					id: meta.arg.faxLineId,
					state: 'succeeded',
				}),
			})
		);

		builder.addMatcher(
			isAnyOf(
				setFaxLineAlias.rejected,
				addFaxLineOwners.rejected,
				removeFaxLineOwners.rejected,
				setFaxLineCallerId.rejected
			),
			(state, { payload: error, meta }) => {
				return {
					...state,
					updating: updateItems(state.updating, {
						id: meta.arg.faxLineId,
						state: 'failed',
						error,
					} as FaxLineOperationState),
				};
			}
		);
	},
});

const legacyReducer = handleActions<FaxlineState, PossibleActions<typeof setGroupAlias>>(
	{
		GROUP_ALIAS_SET_SUCCESS: (state, { data }) => {
			return {
				...state,
				items: state.items.map(faxline => {
					if (faxline.ownerIds.includes(data.groupId)) {
						return {
							...faxline,
							alias: data.alias,
						};
					}

					return faxline;
				}),
			};
		},
	},
	initialState
);

export const reducer = (state: FaxlineState, action: UnknownAction) => {
	return slice.reducer(legacyReducer(state, action), action);
};
