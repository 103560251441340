import React from 'react';
import { Checkbox } from '@panda/ui';

import classes from './LabelsFilter.module.scss';
import { Translate } from '../../../redux/slices/translations';
import { ChangeUrlParameter } from '../selections/types';
import { FilterDropdown } from './FilterDropdown';
import { Filter } from './Filter';
import Spinner from '../../../components/spinner/Spinner';
import { State } from '../state/state';
import { ApiLabel } from '../../../api/types/labels';

interface Props {
	open?: boolean;
	translate: Translate;
	onOpenClose?: (open: boolean) => void;
	selection: number[];
	labels: State['labels'];
	onChange: ChangeUrlParameter;
}

export class LabelsFilter extends React.PureComponent<Props> {
	private getSelectedLabelId(label: ApiLabel) {
		return this.props.selection.find(l => label.id === l);
	}

	private getSelectedLabels() {
		if (this.props.labels !== null) {
			return this.props.labels.filter(label => label.id === this.getSelectedLabelId(label));
		}
		return undefined;
	}

	private onSelectedLabel = (label: ApiLabel) => {
		const changed = Array.from(this.props.selection);
		if (this.isLabelChecked(label)) {
			changed.splice(changed.indexOf(label.id), 1);
			this.props.onChange({ labelIds: changed, offset: 0 });
		} else {
			changed.push(label.id);
			this.props.onChange({ labelIds: changed, offset: 0 });
		}
	};

	private isLabelChecked = (label: ApiLabel) => !!this.props.selection.find(l => label.id === l);

	private isFilterActive() {
		return this.props.selection.length !== 0;
	}

	private renderButtonLabel() {
		if (this.props.selection === null) {
			return this.props.translate('FILTER_LABEL');
		}

		const selectedLabels = this.getSelectedLabels();

		if (this.props.selection && selectedLabels !== undefined) {
			if (selectedLabels.length === 1) {
				return selectedLabels[0].name;
			}
			if (selectedLabels.length > 1) {
				return `${this.props.translate('FILTER_LABEL')} (${selectedLabels.length})`;
			}
		}

		return this.props.translate('FILTER_LABEL');
	}

	private renderFilter(label: ApiLabel) {
		return (
			<li key={label.id}>
				<Checkbox checked={this.isLabelChecked(label)} onChange={() => this.onSelectedLabel(label)}>
					{label.name}
				</Checkbox>
			</li>
		);
	}

	private renderSpinner() {
		return (
			<div className={classes.spinnerWrapper}>
				<Spinner className={classes.spinner} />
			</div>
		);
	}

	private renderEmptyState() {
		return (
			<div className={classes.emptyState}>
				<p className={classes.headline}>{this.props.translate('CREATE_FIRST_LABEL_HEADLINE')}</p>
				<div className={classes.description}>
					{this.props.translate.markdown.block('LABEL_FILTER_EMPTY_HINT')}
				</div>
			</div>
		);
	}

	private renderContent() {
		if (this.props.labels === null) {
			return this.renderSpinner();
		}

		if (this.props.labels.length === 0) {
			return this.renderEmptyState();
		}

		return (
			<ul className={classes.filterlist}>
				{this.props.labels.map(label => this.renderFilter(label))}
			</ul>
		);
	}

	private renderDropdown() {
		if (!this.props.onOpenClose) {
			return null;
		}

		return (
			<FilterDropdown
				buttonLabel={this.renderButtonLabel()}
				active={this.isFilterActive()}
				onOpenClose={this.props.onOpenClose}
				open={!!this.props.open}
				className={classes.dropdown}
			>
				{this.renderContent()}
			</FilterDropdown>
		);
	}

	public render() {
		if (this.props.onOpenClose) {
			return this.renderDropdown();
		}

		return (
			<Filter title={this.props.translate('FILTER_LABEL')}>
				<div className={classes.twoColumns}>{this.renderContent()}</div>
			</Filter>
		);
	}
}
