import React from 'react';
import classnames from 'classnames';
import { useTheme } from '@web-apps/theming';
import { ActionElement, ButtonDefinition, LinkDefinition } from '../ActionElement';
import { Headline } from '../heading/Headline';
import NeoShapes from '../../assets/illustrations/neo_shapes.svg';
import NeoShape1 from '../../assets/illustrations/neo_shape_1.svg';
import NeoShape2 from '../../assets/illustrations/neo_shape_2.svg';
import NeoShape3 from '../../assets/illustrations/neo_shape_3.svg';
import NeoShape4 from '../../assets/illustrations/neo_shape_4.svg';
import NeoShape5 from '../../assets/illustrations/neo_shape_5.svg';

import classes from './EmptyState.module.css';

type BaseProps = {
	/**
	 * Überschrift für die kurze Erläuterung. Sollte nicht das selbe wie die Überschrift der Seite oder des Abschnitts sein.
	 */
	heading: string;
	/**
	 * Ein kurzer Text zur Erläuterung, um was es an dieser Stelle eigentlich geht.
	 */
	text: React.ReactNode;
	/**
	 * Bereich für Aktionselemente, PandaButtons oder PandaLinks, z. B. zum Hinzufügen oder Buchen.
	 *
	 * **LinkDefinition**:
	 *
	 * `{ type: 'link'; to: string; label: string; loud?: boolean; direction?: 'internal' | 'external' }`
	 *
	 * **ButtonDefinition**:
	 *
	 * `{ type: 'button'; onClick: () => void; label: string; loud?: boolean; disabled?: boolean }`
	 */
	actionElements: (LinkDefinition | ButtonDefinition)[];
	/**
	 *
	 * Die Illustration die angezeigt werden soll.
	 */
	image: React.ReactNode;
	neoLightImage?: React.ReactNode;
	neoDarkImage?: React.ReactNode;
};

type ViewProps = BaseProps & {
	context: 'view';
};

type SectionProps = BaseProps & {
	context: 'section';
	shape: 1 | 2 | 3 | 4 | 5;
};

type Props = ViewProps | SectionProps;

const styles = {
	containerwrapper: classnames('@container', 'flex-1'),
	container: classnames(
		'flex',
		'items-center',
		'justify-center',
		'max-w-full',
		'flex-col',
		'@[48rem]:flex-row',
		'gap-40'
	),
	imageContainer: classnames(
		'flex',
		'justify-around',
		'lg:justify-end',
		'max-w-full',
		'xs:max-w-xs',
		'select-none',
		'pointer-events-none'
	),
	fallbackImage: classnames(
		'max-w-[66%]',
		'max-h-[50vh]',
		'@[48rem]:max-w-[25vw]',
		'@[48rem]:max-h-[280px]'
	),
	contentContainer: classnames('max-w-full', 'sm:max-w-md'),
	headline: (isClassic: boolean, context: 'view' | 'section') =>
		classnames(
			'font-brand',
			'font-bold',
			'text-neo-color-global-content-neutral-intense',
			'text-lg/24',
			'lg:text-xl/24',
			'mt-0',
			isClassic && context === 'view' && 'lg:mt-64',
			'mb-8',
			'hyphens'
		),
	text: classnames(
		'font-brand',
		'font-normal',
		'text-neo-color-global-content-neutral-intense',
		'text-base/24',
		'mt-8',
		'mb-16',
		'lg:my-16'
	),
	actionElements: classnames('flex', 'gap-8', 'flex-row', 'flex-wrap'),
};

const EmptyState = (props: Props): JSX.Element => {
	const {
		heading,
		text,
		actionElements,
		image,
		neoLightImage,
		neoDarkImage,
		context = 'view',
	} = props;
	const { theme } = useTheme();

	const renderShape = () => {
		if (context !== 'section' || !('shape' in props)) return null;

		switch (props.shape) {
			case 1:
				return <img alt="" src={NeoShape1} className={classes.shapeImage} />;
			case 2:
				return <img alt="" src={NeoShape2} className={classes.shapeImage} />;
			case 3:
				return <img alt="" src={NeoShape3} className={classes.shapeImage} />;
			case 4:
				return <img alt="" src={NeoShape4} className={classes.shapeImage} />;
			case 5:
				return <img alt="" src={NeoShape5} className={classes.shapeImage} />;
			default:
				return null;
		}
	};

	const renderImage = () => {
		switch (theme) {
			case 'classic-light':
				return image;
			case 'neo-light':
				return context === 'view'
					? neoLightImage || <img alt="" src={NeoShapes} className={styles.fallbackImage} />
					: neoLightImage || renderShape();
			case 'neo-dark':
				return context === 'view'
					? neoDarkImage || <img alt="" src={NeoShapes} className={styles.fallbackImage} />
					: neoDarkImage || renderShape();
		}
	};

	const renderActionElements = () => {
		return (
			<div className={styles.actionElements}>
				{actionElements.map((element, i) => (
					// eslint-disable-next-line react/no-array-index-key
					<ActionElement key={i} element={element} />
				))}
			</div>
		);
	};

	return (
		<div className={styles.containerwrapper}>
			<div className={styles.container}>
				<div className={styles.imageContainer}>{renderImage()}</div>
				<div className={styles.contentContainer}>
					<Headline className={styles.headline(theme === 'classic-light', context)}>
						{heading}
					</Headline>
					<p className={styles.text}>{text}</p>
					<div>{renderActionElements()}</div>
				</div>
			</div>
		</div>
	);
};

export { EmptyState };
