import { useEffect } from 'react';
import { useDispatch, useSelector } from '../../utils/wrapper';
import { fetchDevices, setCallRecording, setTariffAnnouncement } from './actions';
import { getAppDevices, getOwnDevices, isAppDevice } from './selectors';
import { useAction } from '../../index';

export const useUserDevices = (userId: string) => {
	const devicesState = useSelector(state => state.devices);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchDevices(userId));
	}, [dispatch, userId]);

	const devices = getOwnDevices(devicesState.items, userId);
	const registerDevices = devices.filter(device => device.type === 'REGISTER');
	const appDevices = getAppDevices(devicesState.items).filter(device => device.owner === userId);

	return {
		devices,
		devicesFetchedForUser: devicesState.fetchedForUser.includes(userId),
		registerDevices,
		appDevices,
	};
};

export const useAllDevices = (userId: string | undefined) => {
	const devicesState = useSelector(state => state.devices);
	const dispatch = useDispatch();

	useEffect(() => {
		if (userId) {
			dispatch(fetchDevices(userId));
		}
	}, [dispatch, userId]);

	return {
		data: devicesState.items.filter(device => !isAppDevice(device)),
		fetched: userId && devicesState.fetchedForUser.includes(userId),
	};
};

export const useAppDevice = (userId: string) => {
	const devicesState = useSelector(state => state.devices);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchDevices(userId));
	}, [dispatch, userId]);

	const appDevices = getAppDevices(devicesState.items.filter(device => device.owner === userId));

	return {
		data: appDevices.length > 0 ? appDevices[0] : null,
		fetched: devicesState.fetchedForUser.includes(userId),
	};
};

export const useDeviceActions = () => ({
	setCallRecording: useAction(setCallRecording),
	setTariffAnnouncement: useAction(setTariffAnnouncement),
});
