import { useEffect } from 'react';
import {
	Acd,
	createAcd,
	deleteAcd,
	fetchAcds,
	setAcdAgentDevices,
	setAcdAgents,
	setAcdFollowUpTime,
	setAcdGreetingAudioFile,
	setAcdName,
	setAcdQueueWaitingAudioFile,
	setAcdRingingOrder,
	setAcdRingTime,
	setAcdTeamLeads,
	toggleChannelLoginStatus,
} from './index';
import { useDispatch, useSelector } from '../../utils/wrapper';
import { SliceData, useAction } from '../../index';

export const useChannels = (): SliceData<Acd[]> => {
	const acds = useSelector(state => state.acds);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchAcds());
	}, [dispatch]);

	if (!acds.fetched) {
		return {
			data: null,
			fetched: false,
		};
	}

	return {
		data: acds.items,
		fetched: true,
	};
};

/**
	@deprecated Do not use this old spelling. Use useChannels instead.
 */
export const useAcds = useChannels;

export const useChannelActions = () => ({
	createChannel: useAction(createAcd),
	setChannelAgents: useAction(setAcdAgents),
	setChannelAgentDevices: useAction(setAcdAgentDevices),
	setChannelName: useAction(setAcdName),
	deleteChannel: useAction(deleteAcd),
	setChannelQueueWaitingAudioFile: useAction(setAcdQueueWaitingAudioFile),
	setChannelGreetingAudioFile: useAction(setAcdGreetingAudioFile),
	setChannelFollowUpTime: useAction(setAcdFollowUpTime),
	setChannelRingTime: useAction(setAcdRingTime),
	setChannelRingingOrder: useAction(setAcdRingingOrder),
	setChannelTeamLeads: useAction(setAcdTeamLeads),
	toggleChannelLoginStatus: useAction(toggleChannelLoginStatus),

	/**
	 * @deprecated use createChannel instead
	 */
	createAcd: useAction(createAcd),
	/**
	 * @deprecated use setChannelAgents instead
	 */
	setAcdAgents: useAction(setAcdAgents),
	/**
	 * @deprecated use setChannelAgentDevices instead
	 */
	setAcdAgentDevices: useAction(setAcdAgentDevices),
	/**
	 * @deprecated use setChannelName instead
	 */
	setAcdName: useAction(setAcdName),
	/**
	 * @deprecated use deleteChannel instead
	 */
	deleteAcd: useAction(deleteAcd),
	/**
	 * @deprecated use setChannelQueueWaitingAudioFile instead
	 */
	setAcdQueueWaitingAudioFile: useAction(setAcdQueueWaitingAudioFile),
	/**
	 * @deprecated use setChannelGreetingAudioFile instead
	 */
	setAcdGreetingAudioFile: useAction(setAcdGreetingAudioFile),
	/**
	 * @deprecated use setChannelFollowUpTime instead
	 */
	setAcdFollowUpTime: useAction(setAcdFollowUpTime),
	/**
	 * @deprecated use setChannelRingTime instead
	 */
	setAcdRingTime: useAction(setAcdRingTime),
	/**
	 * @deprecated use setChannelRingingOrder instead
	 */
	setAcdRingingOrder: useAction(setAcdRingingOrder),
	/**
	 * @deprecated use setChannelTeamLeads instead
	 */
	setAcdTeamLeads: useAction(setAcdTeamLeads),
});

/**
 * @deprecated use useChannelActions instead
 */
export const useAcdActions = useChannelActions;
