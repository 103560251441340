import { createSlice } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { ChannelHistoryItem, ChannelHistoryState } from './types';
import { fetchHistory, fetchHistoryForChannels } from './actions';
import { randomString } from './utils';

export const initialState: ChannelHistoryState = {
	items: {},
	fetching: [],
	fetched: {},
	errors: {},
	revision: randomString(12),
};

export const reducer = createSlice({
	name: 'channelHistory',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchHistory.pending, (state, action) => ({
			...state,
			fetching: Array.from(new Set(state.fetching).add(action.meta.arg.channelId)),
			revision: randomString(12),
		}));

		builder.addCase(fetchHistory.fulfilled, (state, action) => {
			const fetching = new Set(state.fetching);
			fetching.delete(action.meta.arg.channelId);

			const items = { ...state.items };
			items[action.meta.arg.channelId] = action.payload;

			const fetched = { ...state.fetched };
			fetched[action.meta.arg.channelId] = DateTime.now().toISO();

			return {
				...state,
				items,
				fetched,
				fetching: Array.from(fetching),
				revision: randomString(12),
			};
		});

		builder.addCase(fetchHistory.rejected, (state, { payload, meta }) => {
			const errors = { ...state.errors };

			if (payload) {
				errors[meta.arg.channelId] = payload;
			}

			return {
				...state,
				errors,
				revision: randomString(12),
			};
		});

		builder.addCase(fetchHistoryForChannels.pending, (state, { meta }) => {
			const channelIds = meta.arg.channelIds;
			const items = {} as Record<string, ChannelHistoryItem[]>;
			Object.keys(state.items).forEach(key => {
				if (channelIds.includes(key)) {
					items[key] = state.items[key];
				}
			});

			// const fetched = {} as Record<string, string>;
			// Object.keys(state.fetched).forEach(key => {
			// 	if (channelIds.includes(key)) {
			// 		items[key] = state.items[key];
			// 	}
			// });

			return { ...state, items };
		});
	},
}).reducer;
