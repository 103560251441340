import { createSelector } from '@reduxjs/toolkit';
import { Faxline, FaxLineOperationState, FaxlineState } from './types';

const selectFaxLinesItems = (state: FaxlineState) => state.items;
const selectFaxLineFetchItems = (state: FaxlineState) => state.fetching;

const createFaxLineSelector = createSelector.withTypes<FaxlineState>();

export const selectLoadedFaxLines = createFaxLineSelector(
	[selectFaxLinesItems, selectFaxLineFetchItems],
	(faxLines: Faxline[], fetchItems: FaxLineOperationState[]) => {
		const fetchedIds = fetchItems.filter(f => f.state === 'succeeded').map(f => f.id);

		return faxLines.filter(f => fetchedIds.includes(f.id));
	}
);

export const faxLinesFetched = createFaxLineSelector(
	[selectFaxLineFetchItems],
	(items: FaxLineOperationState[]) => items.some(i => i.state === 'succeeded' && i.id === '*')
);

export const selectFaxLine = createSelector.withTypes<FaxlineState>()(
	[selectFaxLinesItems, (_, faxLineId: string) => faxLineId],
	(items, faxLineId) => items.find(f => f.id === faxLineId)
);

export const selectFaxlinesForOwner = createFaxLineSelector(
	[selectFaxLinesItems, (_, ownerId: string) => ownerId],
	(faxlines: Faxline[], ownerId: string) =>
		faxlines.filter(fax => fax.ownerIds.some(id => id === ownerId))
);

export const selectFaxlinesForOwners = createFaxLineSelector(
	[selectFaxLinesItems, (_, ownerIds: string[]) => ownerIds],
	(faxlines: Faxline[], ownerIds: string[]) =>
		faxlines.filter(fax => fax.ownerIds.some(id => ownerIds.includes(id)))
);

export const selectUserFaxLine = createFaxLineSelector(
	[selectFaxLinesItems, (_, ownerId: string) => ownerId],
	(faxlines: Faxline[], ownerId: string) => {
		return faxlines.find(
			faxline => faxline.ownerIds.length === 0 && faxline.ownerIds[0] === ownerId
		);
	}
);
