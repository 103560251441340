import { ErrorBase, isErrorBase, OperationState } from '../../utils/types';

export type Faxline = {
	id: string;
	ownerIds: string[];
	alias: string;
	tagline: string;
	callerId: string;
	canReceive: boolean;
};

export interface WithId {
	id: string;
}

export type FaxLineOperationState = WithId &
	(
		| { state: Omit<OperationState, 'failed'> }
		| {
				state: Extract<OperationState, 'failed'>;
				error: FaxLineError;
		  }
	);

export interface FaxlineState {
	items: Faxline[];
	fetching: FaxLineOperationState[];
	updating: FaxLineOperationState[];
}

const errorNames = [
	'COULD_NOT_FETCH_FAX_LINES',
	'COULD_NOT_SET_FAX_LINE_ALIAS',
	'COULD_NOT_ADD_FAX_LINE_MEMBERS',
	'COULD_NOT_REMOVE_FAX_LINE_MEMBERS',
	'COULD_NOT_CREATE_FAX_LINE',
];

type FaxLineErrorName = (typeof errorNames)[number];

export type FaxLineError = ErrorBase<FaxLineErrorName>;

export const CouldNotFetchFaxLines = (): FaxLineError => ({
	name: 'COULD_NOT_FETCH_FAX_LINES',
	message: 'Could not fetch fax lines',
});

export const CouldNotSetFaxLineAlias = (id: string): FaxLineError => ({
	name: 'COULD_NOT_SET_FAX_LINE_ALIAS',
	message: `Could not set fax line alias for ${id}`,
});

export const CouldNotAddFaxLineMembers = (id: string): FaxLineError => ({
	name: 'COULD_NOT_ADD_FAX_LINE_MEMBERS',
	message: `Could not add fax line members for ${id}`,
});

export const CouldNotRemoveFaxLineMembers = (id: string): FaxLineError => ({
	name: 'COULD_NOT_REMOVE_FAX_LINE_MEMBERS',
	message: `Could not remove fax group members for ${id}`,
});

export const isFaxLineError = (error: unknown): error is FaxLineError => {
	return isErrorBase(error, errorNames);
};
