import React from 'react';
import classnames from 'classnames';
import classes from './RoundProgressIndicator.module.scss';

interface Props {
	value: number;
	unit?: string;
	max?: number;
	variant?: 'small' | 'large';
	clockwise?: boolean;
}

const RoundProgressIndicator = ({
	value,
	unit,
	max = 100,
	variant = 'large',
	clockwise = true,
}: Props) => {
	const RADIUS = 45;
	const dashArray = 2 * Math.PI * RADIUS;
	const dashOffset = dashArray * (1 - value / max) * (!clockwise ? -1 : 1);

	return (
		<svg
			viewBox="0, 0, 106, 106"
			className={classnames(classes.roundProgressIndicator, classes[variant])}
		>
			<defs>
				<linearGradient
					id="paint0_linear_5_7445"
					x1="0.499939"
					y1="-189.75"
					x2="95.5"
					y2="-189.75"
					gradientUnits="userSpaceOnUse"
					gradientTransform="rotate(90)"
				>
					<stop stopColor="var(--neo-color-global-gradient-3-accents-0" />
					<stop offset="0.505208" stopColor="var(--neo-color-global-gradient-3-accents-50)" />
					<stop offset="1" stopColor="var(--neo-color-global-gradient-3-accents-100)" />
				</linearGradient>
			</defs>
			<circle r={RADIUS} cx="50%" cy="50%" className={classes.background} />
			<circle
				r={RADIUS}
				cx="50%"
				cy="50%"
				className={classes.circle}
				stroke="url(#paint0_linear_5_7445)"
				strokeDasharray={dashArray}
				strokeDashoffset={dashOffset}
			/>
			<g>
				<text className={classes.value} dx="50%" dy="42%">
					{value}
				</text>
				<text className={classes.unit} dx="50%" dy="65%">
					{unit}
				</text>
			</g>
		</svg>
	);
};

export default RoundProgressIndicator;
