import pathToRegex from 'path-to-regexp';

import { ApiLinks } from '../../api/types/links';
import {
	SideNavigationItem,
	SideNavigationMenu,
	SideNavigationMenuGroup,
	SideNavigationMenuLink,
} from '../../navigation/SideNavigation/SideNavigation.types';
import { getRestriction, hasRestriction, RestrictionState } from '../../redux/modules/restrictions';
import { hasFeatureToggle, UserInfoState } from '../../redux/slices/userinfo';
import {
	accountManagementPath,
	acdsPath,
	administrationPath,
	affiliatePath,
	apiClientsPath,
	appPath,
	conferenceRoomsPath,
	faxLinesPath,
	groupsPath,
	hardwarePath,
	ivrsPath,
	locationsPath,
	mobileTelephonyPath,
	phonenumbersPath,
	reportsPath,
	samlSsoPath,
	sipgateIoPath,
	telephonySettingsPath,
	trunksPath,
	unlinkedDevicesPath,
	usersPath,
} from '../../routes/paths';
import accountingIcon from './accountMenuIcons/accounting.svg';
import accountOverviewIcon from './accountMenuIcons/dashboard.svg';
import productIcon from './accountMenuIcons/product.svg';
import telephonyIcon from './accountMenuIcons/telephony.svg';
import auth from '../authenticate/auth';

const numberPaths = [
	pathToRegex(`/team/settings/phonenumbers`, { end: false }),
	pathToRegex(`/team/settings/numbers`, { end: false }),
	pathToRegex(`/team/settings/portednumbers`, { end: false }),
	pathToRegex(`/team/settings/mobilenumberimport`, { end: false }),
	phonenumbersPath.regex,
];

const unlinkedPhonePaths = [
	unlinkedDevicesPath.regex,
	pathToRegex(`/team/settings/setup/unlinked`, { end: false }),
	pathToRegex(`/team/settings/forwarding/unlinked`, { end: false }),
];

const isNumberSettingsActive = (path: string) => numberPaths.some(pth => pth.test(path));

const isUnlinkedPhonesActive = (path: string) => unlinkedPhonePaths.some(pth => pth.test(path));

const getTelephonyGroup = (
	restrictions: RestrictionState,
	webuserId: string,
	userInfo: UserInfoState
): SideNavigationMenuGroup => {
	const telephonyLinks: SideNavigationMenuLink[] = [];

	if (hasRestriction(restrictions.items, 'CAN_USE_PBX_FEATURES')) {
		telephonyLinks.push({
			kind: 'LINK',
			path: usersPath.build(),
			title: 'USER_ADMINISTRATION',
		});
		telephonyLinks.push({
			kind: 'LINK',
			path: phonenumbersPath.build(),
			title: 'ACCOUNT_NAVIGATION_PHONENUMBERS',
			isActive: isNumberSettingsActive,
		});
		telephonyLinks.push({
			kind: 'LINK',
			path: locationsPath.build(),
			title: 'ACCOUNT_NAVIGATION_LOCATIONS',
		});
	}

	if (
		hasRestriction(restrictions.items, 'CAN_CREATE_MOBILE_DEVICE', webuserId) ||
		hasRestriction(restrictions.items, 'CAN_ACTIVATE_SIM', webuserId)
	) {
		telephonyLinks.push({
			kind: 'LINK',
			path: mobileTelephonyPath.build(),
			title: 'ACCOUNT_NAVIGATION_MOBILE_TELEPHONY_TITLE',
		});
	}

	if (
		hasRestriction(restrictions.items, 'CAN_USE_PBX_FEATURES') &&
		hasRestriction(restrictions.items, 'CAN_USE_GROUPS')
	) {
		telephonyLinks.push({
			kind: 'LINK',
			path: groupsPath.build(),
			title: 'GROUP_ADMINISTRATION',
		});
	}

	const hasSContractButNoFeatureToggle = (userInfoState: UserInfoState) => {
		if (hasRestriction(restrictions.items, 'CAN_USE_CHANNEL_CALLCENTER_FEATURES')) {
			return false;
		}

		return !hasFeatureToggle(userInfoState.data, 'CHANNELS_FOR_S_CONTRACT');
	};
	if (
		hasRestriction(restrictions.items, 'CAN_USE_PBX_FEATURES') &&
		hasRestriction(restrictions.items, 'CAN_USE_CHANNELS') &&
		!hasSContractButNoFeatureToggle(userInfo)
	) {
		telephonyLinks.push({
			kind: 'LINK',
			path: acdsPath.build(),
			title: 'CHANNEL_ADMINISTRATION',
		});
	}

	if (hasRestriction(restrictions.items, 'CAN_USE_UNLINKED_DEVICES')) {
		telephonyLinks.push({
			kind: 'LINK',
			path: unlinkedDevicesPath.build(),
			title: 'PUBLIC_PHONES_ADMINISTRATION',
			isActive: isUnlinkedPhonesActive,
		});
	}

	if (hasRestriction(restrictions.items, 'CAN_USE_AUTOPROVISION')) {
		telephonyLinks.push({
			kind: 'LINK',
			path: hardwarePath.build(),
			title: 'ACCOUNT_NAVIGATION_PHONES',
		});
	}

	if (
		hasRestriction(restrictions.items, 'CAN_USE_PBX_FEATURES') &&
		hasRestriction(restrictions.items, 'CAN_INDIVIDUALIZE_ACCOUNT')
	) {
		telephonyLinks.push({
			kind: 'LINK',
			path: telephonySettingsPath.build(),
			title: 'ACCOUNT_NAVIGATION_TELEPHONY_SETTINGS_TITLE',
		});
	}

	if (auth.isNeoPBXCustomer() && hasRestriction(restrictions.items, 'CAN_USE_PBX_FEATURES')) {
		telephonyLinks.push({
			kind: 'LINK',
			path: faxLinesPath.build(),
			title: 'ACCOUNT_NAVIGATION_FAX_GROUPS',
		});
	}

	if (!hasRestriction(restrictions.items, 'CAN_USE_PBX_FEATURES')) {
		telephonyLinks.push({
			kind: 'LINK',
			path: phonenumbersPath.build(),
			title: 'ACCOUNT_NAVIGATION_NUMBERS',
		});
		telephonyLinks.push({
			kind: 'LINK',
			path: locationsPath.build(),
			title: 'ACCOUNT_NAVIGATION_LOCATIONS',
		});
	}

	return {
		kind: 'GROUP',
		icon: telephonyIcon,
		title: 'ACCOUNT_NAVIGATION_HEADING_TELEPHONY',
		links: telephonyLinks,
	};
};

const getProductLinks = (
	links: ApiLinks,
	restrictions: RestrictionState,
	webuserId: string,
	userInfo: UserInfoState
): SideNavigationMenuGroup => {
	const productLinks: SideNavigationMenuLink[] = [];
	const hasMSTeams = hasFeatureToggle(userInfo.data, 'MS_TEAM_BETA');

	if (
		hasRestriction(restrictions.items, 'CAN_USE_SIPGATE_APP', webuserId) &&
		userInfo.fetched &&
		!userInfo.data.isNeoPBXCustomer
	) {
		productLinks.push({
			kind: 'LINK',
			path: appPath.build(),
			title: 'APP_WIDGET_HEADLINE_CLINQ',
		});
	}

	productLinks.push({
		kind: 'LINK',
		path: trunksPath.build(),
		title: 'ACCOUNT_NAVIGATION_TRUNKS',
	});

	if (hasRestriction(restrictions.items, 'CAN_USE_CONFERENCE_ROOMS')) {
		productLinks.push({
			kind: 'LINK',
			path: conferenceRoomsPath.build(),
			title: 'ACCOUNT_NAVIGATION_CONFERENCEROOM',
		});
	}

	if (hasRestriction(restrictions.items, 'CAN_USE_IVR')) {
		productLinks.push({
			kind: 'LINK',
			path: ivrsPath.build(),
			title: 'IVR_ADMINISTRATION',
		});
	}

	const canBookSipgateIO = getRestriction(restrictions.items, 'CAN_BOOK_SIPGATE_IO');
	if (
		hasRestriction(restrictions.items, 'CAN_BOOK_SIPGATE_IO') ||
		(!canBookSipgateIO.value && canBookSipgateIO.reason === 'NO_AUTOCREDITING') ||
		hasRestriction(restrictions.items, 'CAN_USE_SIPGATE_IO')
	) {
		productLinks.push({
			kind: 'LINK',
			path: sipgateIoPath.build(),
			title: 'WEBHOOKS_LINK',
		});
		if (hasRestriction(restrictions.items, 'CAN_USE_SIPGATE_IO')) {
			productLinks.push({
				kind: 'LINK',
				path: apiClientsPath.build(),
				title: 'API_CLIENTS_LINK',
			});
		}
	}
	// todo: fix von kraftwerk integrieren
	if (hasRestriction(restrictions.items, 'CAN_USE_PBX_FEATURES')) {
		productLinks.push({
			kind: 'LINK',
			external: true,
			path: links.crmIntegrationsUrl,
			title: 'ACCOUNT_NAVIGATION_INTEGRATIONS_TITLE',
			pill: 'NEW',
		});
	}

	productLinks.push({
		kind: 'LINK',
		path: hasMSTeams
			? links.microsoftTeamsUrl
			: 'https://info.sipgate.de/sipgate-ms-teams-erste-infos-meeting',
		title: 'ACCOUNT_NAVIGATION_MS_TEAMS_TITLE',
		external: !hasMSTeams,
	});

	return {
		kind: 'GROUP',
		icon: productIcon,
		title: 'ACCOUNT_NAVIGATION_HEADING_PRODUCTS',
		links: productLinks,
	};
};

const getAccountLinks = (
	links: ApiLinks,
	restrictions: RestrictionState,
	userInfo: UserInfoState
): SideNavigationMenuGroup => {
	const accountLinks: SideNavigationMenuLink[] = [];

	if (hasRestriction(restrictions.items, 'CAN_USE_AFFILIATE')) {
		accountLinks.push({
			kind: 'LINK',
			path: affiliatePath.build(),
			title: 'ACCOUNT_NAVIGATION_AFFILIATE',
		});
	}

	accountLinks.push({
		kind: 'LINK',
		path: administrationPath.build(),
		title: 'ACCOUNT_NAVIGATION_ACCOUNT',
	});

	if (hasRestriction(restrictions.items, 'CAN_USE_SAML_SSO')) {
		accountLinks.push({
			kind: 'LINK',
			path: samlSsoPath.build(),
			title: 'ACCOUNT_NAVIGATION_SAML_SSO',
		});
	}

	if (hasFeatureToggle(userInfo.data, 'REPORTS_HUB')) {
		accountLinks.push({
			kind: 'LINK',
			path: reportsPath.build(),
			title: 'ACCOUNT_NAVIGATION_REPORTS_HUB',
		});
	}

	return {
		kind: 'GROUP',
		icon: accountingIcon,
		title: 'ACCOUNT_NAVIGATION_HEADING_ADMINISTRATION',
		links: accountLinks,
	};
};

export const getAccountMenuLinks = (
	links: ApiLinks,
	restrictions: RestrictionState,
	webuserId: string,
	userinfo: UserInfoState
): SideNavigationMenu => {
	const items: SideNavigationItem[] = [];

	items.push({
		kind: 'LINK',
		path: accountManagementPath.build(),
		title: 'ADMIN_DASHBOARD',
		icon: accountOverviewIcon,
	});

	items.push(getTelephonyGroup(restrictions, webuserId, userinfo));
	items.push(getProductLinks(links, restrictions, webuserId, userinfo));
	items.push(getAccountLinks(links, restrictions, userinfo));

	return { items };
};
