import { ErrorBase, isErrorBase } from '../../utils/types';

export interface DataAutomationHistoryState {
	items: DataAutomationHistoryItem[];
	fetchingForContainer: string[];
	fetchedForContainer: string[];
}

export interface DataAutomationHistoryItem {
	containerId: string;
	count: number;
}

const errorNames = [
	'COULD_NOT_FETCH_DATA_AUTOMATION_HISTORY',
	'COULD_NOT_FIND_DATA_AUTOMATION_HISTORY',
];

type DataAutomationHistoryErrorName = (typeof errorNames)[number];

export type DataAutomationHistoryError = ErrorBase<DataAutomationHistoryErrorName>;

export const CouldNotFetchDataAutomationHistory = (): DataAutomationHistoryError => ({
	name: 'COULD_NOT_FETCH_DATA_AUTOMATION_HISTORY',
	message: 'Could not fetch data automation history',
});

export const CouldNotFindDataAutomationHistory = (faxLine: string) => ({
	name: 'COULD_NOT_FIND_DATA_AUTOMATION_HISTORY',
	message: `Could not fetch data automation history ${faxLine}`,
});

export const isDataAutomationHistoryError = (
	error: unknown
): error is DataAutomationHistoryError => {
	return isErrorBase(error, errorNames);
};
