import { createAsyncThunk } from '../../utils/wrapper';
import {
	CouldNotAddFaxLineMembers,
	CouldNotRemoveFaxLineMembers,
	CouldNotSetFaxLineAlias,
	FaxLineError,
} from './types';
import { isNeoPBXCustomer } from '../userinfo';
import { ApiFaxline, ApiFaxLineNeo, faxLineRestApiClient as api } from './api';

type ForceFetch = { forceFetch?: boolean };

export const fetchFaxLines = createAsyncThunk<ApiFaxline[], ForceFetch>(
	'faxLines/fetch',
	async (_args: ForceFetch) => {
		return (await api.getFaxLines()).items;
	},
	{
		condition: ({ forceFetch = false }: ForceFetch, { getState }) => {
			const fetched = getState().faxlines.fetching.some(
				i => i.id === '*' && i.state === 'succeeded'
			);
			return !fetched || forceFetch;
		},
	}
);

export type SetFaxLineAliasParameter = {
	faxLineId: string;
	alias: string;
};

export const setFaxLineAlias = createAsyncThunk<
	void,
	SetFaxLineAliasParameter,
	{ rejectValue: FaxLineError }
>(
	'faxLines/setAlias',
	async ({ faxLineId, alias }: SetFaxLineAliasParameter, { rejectWithValue }) => {
		try {
			await api.setFaxLineAlias(faxLineId, alias);
		} catch (error) {
			return rejectWithValue(CouldNotSetFaxLineAlias(faxLineId));
		}
	}
);

export type SetFaxLineTagLineParameter = {
	faxLineId: string;
	tagline: string;
};

export const setFaxLineTagLine = createAsyncThunk<void, SetFaxLineTagLineParameter>(
	'faxLines/setTagLine',
	async ({ faxLineId, tagline }: SetFaxLineTagLineParameter) => {
		return api.setFaxLineTagline(faxLineId, tagline);
	}
);

export type CreateFaxLineParameters = {
	ownerIds: string[];
	alias: string;
};

export const createFaxLine = createAsyncThunk<ApiFaxline | ApiFaxLineNeo, CreateFaxLineParameters>(
	'faxLines/create',
	async ({ ownerIds, alias }: CreateFaxLineParameters, { getState }) => {
		if (isNeoPBXCustomer(getState().userinfo.data)) {
			return (await api.createFaxLineNeo(ownerIds, alias)) as ApiFaxLineNeo;
		}

		if (ownerIds.length === 0) {
			throw new Error('classic pbx faxes needs a owner');
		}

		return (await api.createFaxLine(ownerIds[0], alias)) as ApiFaxline;
	}
);

export type DeleteFaxLineParameters = {
	faxLineId: string;
};

export const deleteFaxLine = createAsyncThunk<void, DeleteFaxLineParameters>(
	'faxLines/delete',
	async ({ faxLineId }: DeleteFaxLineParameters) => {
		return api.deleteFaxLine(faxLineId);
	},
	{
		condition: ({ faxLineId }: DeleteFaxLineParameters, { getState }) => {
			return getState()
				.restrictions.items.filter(
					r => r.restriction === 'CAN_DELETE_FAX_EXTENSION' && r.target === faxLineId
				)
				.every(r => r.value);
		},
	}
);

export type SetFaxLineCallerIdParameters = {
	faxLineId: string;
	callerId: string;
	anonymous: boolean;
};

export const setFaxLineCallerId = createAsyncThunk<void, SetFaxLineCallerIdParameters>(
	'faxLines/setFaxLineCallerId',
	async ({ faxLineId, callerId, anonymous }: SetFaxLineCallerIdParameters) => {
		return api.setFaxLineCallerId(faxLineId, callerId, anonymous);
	}
);

export type AddFaxLineOwnersParameter = {
	faxLineId: string;
	ownerIds: string[];
};

export const addFaxLineOwners = createAsyncThunk<
	void,
	AddFaxLineOwnersParameter,
	{
		rejectValue: FaxLineError;
	}
>(
	'faxLines/addOwners',
	async ({ faxLineId, ownerIds }: AddFaxLineOwnersParameter, { rejectWithValue }) => {
		try {
			await api.addFaxLineOwners(faxLineId, ownerIds);
		} catch (error) {
			return rejectWithValue(CouldNotAddFaxLineMembers(faxLineId));
		}
	},
	{
		condition: ({ ownerIds }) => {
			return ownerIds.length > 0;
		},
	}
);

export type RemoveFaxLineMembersActionParameter = AddFaxLineOwnersParameter;

export const removeFaxLineOwners = createAsyncThunk<
	void,
	RemoveFaxLineMembersActionParameter,
	{
		rejectValue: FaxLineError;
	}
>(
	'faxLines/removeOwners',
	async ({ faxLineId, ownerIds }: RemoveFaxLineMembersActionParameter, { rejectWithValue }) => {
		try {
			await api.removeFaxLineOwners(faxLineId, ownerIds);
		} catch (error) {
			return rejectWithValue(CouldNotRemoveFaxLineMembers(faxLineId));
		}
	},
	{
		condition: ({ ownerIds }) => {
			return ownerIds.length > 0;
		},
	}
);
