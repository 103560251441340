export type OperationState = 'idle' | 'pending' | 'succeeded' | 'failed';

export interface ErrorBase<T extends string> {
	name: T;
	message: string;
}

// Generic Type Guard
export function isErrorBase<T extends string>(
	error: unknown,
	validNames: T[]
): error is ErrorBase<T> {
	return (
		typeof error === 'object' &&
		error !== null &&
		'name' in error &&
		'message' in error &&
		validNames.includes((error as ErrorBase<T>).name)
	);
}
