import React from 'react';
import { CSSTransition } from 'react-transition-group';
import classes from './FilterActionBar.module.scss';
import { SelectAll } from './SelectAll';
import { Actions } from './actions/Actions';
import { Selections, TypeType } from './selections/parsers';
import { ChangeUrlParameter } from './selections/types';
import { FilterGroup } from './filters/FilterGroup';
import { NormalizedEvent } from './normalize/events';
import { State } from './state/state';
import { Group } from '../../redux/modules/groups';
import { Phoneline } from '../../api/types/phonelines';
import { Acd } from '../../redux/slices/acds';
import { SipgateDomain } from '../../redux/slices/userinfo';
import { Translate } from '../../redux/slices/translations';

interface Props {
	groups?: Group[];
	phonelines?: Phoneline[];
	acds?: Acd[];
	translate: Translate;
	selections: Selections;
	labels: State['labels'];
	contacts: State['contacts'];
	onFilterChange: ChangeUrlParameter;
	showArchiveButton: boolean;
	showDearchiveButton: boolean;
	hasSelectedEvents: boolean;
	onDelete: () => void;
	onArchive: () => void;
	onDearchive: () => void;
	onMarkRead: () => void;
	onMarkUnread: () => void;
	matchedEventCount: number | null;
	onEditLabels: () => void;
	events: NormalizedEvent[];
	domain: SipgateDomain;
	selectMultiple: (eventIds: string[]) => void;
	selectableTypes: TypeType[];
}

export class FilterActionBar extends React.PureComponent<Props> {
	private canCurrentEventsBeMarkedRead() {
		return !!this.props.events.find(
			event => event.selected && event.originalEvent.read === 'UNREAD'
		);
	}

	public render() {
		return (
			<div className={classes.filterActionBar}>
				<CSSTransition
					in={!this.props.hasSelectedEvents}
					classNames={{
						enter: classes.filtersEnter,
						enterActive: classes.filtersEnterActive,
						exit: classes.filtersExit,
						exitActive: classes.filtersExitActive,
					}}
					timeout={250}
					unmountOnExit
				>
					<FilterGroup
						translate={this.props.translate}
						selections={this.props.selections}
						labels={this.props.labels}
						contacts={this.props.contacts}
						onChange={this.props.onFilterChange}
						matchedEventCount={this.props.matchedEventCount}
						groups={this.props.groups}
						phonelines={this.props.phonelines}
						acds={this.props.acds}
						domain={this.props.domain}
						selectableTypes={this.props.selectableTypes}
					/>
				</CSSTransition>

				<CSSTransition
					in={this.props.hasSelectedEvents}
					classNames={{
						enter: classes.actionsEnter,
						enterActive: classes.actionsEnterActive,
						exit: classes.actionsExit,
						exitActive: classes.actionsExitActive,
					}}
					timeout={250}
					unmountOnExit
				>
					<Actions
						translate={this.props.translate}
						showArchiveButton={this.props.showArchiveButton}
						showDearchiveButton={this.props.showDearchiveButton}
						showMarkReadButton={this.canCurrentEventsBeMarkedRead()}
						showMarkUnreadButton={!this.canCurrentEventsBeMarkedRead()}
						onDelete={this.props.onDelete}
						onArchive={this.props.onArchive}
						onDearchive={this.props.onDearchive}
						onEditLabels={this.props.onEditLabels}
						onMarkRead={this.props.onMarkRead}
						onMarkUnread={this.props.onMarkUnread}
					/>
				</CSSTransition>

				<SelectAll
					translate={this.props.translate}
					selected={this.props.hasSelectedEvents}
					events={this.props.events}
					selectMultiple={this.props.selectMultiple}
				/>
			</div>
		);
	}
}
